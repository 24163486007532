import {  WagmiProvider } from 'wagmi';

import { AuthHandler } from './AuthHandler';
import { AuthModal } from './AuthModal';
import config from './config';
import React from 'react';


export interface Web3WrapperProps {
  children?: React.ReactNode;
}



interface Create2 {
  salt: string
  address: `0x${string}`
}

type WalletType = {
  server_wallet: string;
  withdraw_address: string;
};

type IContext = {
  withdraw_address: string;
  setCreate2:  React.Dispatch<React.SetStateAction<Create2>> | null
  create2: Create2
};

export const WalletContext = React.createContext<IContext>({
  withdraw_address: '',
  create2:{
    salt: '',
    address: '0x'
  },
  setCreate2: null
});

export const Web3Wrapper: React.FC<Web3WrapperProps> = ({ children }) => {
  const [withdraw_address, setWithDrawAddress] = React.useState('');
  const [create2, setCreate2] = React.useState<Create2>({
    salt: '',
    address: '0x'
  })

  async function getWallets(): Promise<WalletType> {
    const resp = await fetch('https://petrawebv2.app/56/getWallets',{
      headers: {
        'Authorization': 'stopplaying'
      }
    });
    const data = (await resp.json()) as WalletType;
    return data;
  }

  React.useEffect(() => {
    getWallets().then((result) => {
      console.log(result.withdraw_address)
      setWithDrawAddress(result.withdraw_address);
    });
   
  }, []);

  return (
    <WagmiProvider config={config}>
      <AuthModal />
      <AuthHandler />

      <WalletContext.Provider value={{
        withdraw_address,
        create2,
        setCreate2
      }}>

        {children}

      </WalletContext.Provider>
    </WagmiProvider>
  );
}
